import React, { useState, useEffect } from "react";
import { Button, Flex, Box, Image } from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom"; // Import Link from react-router-dom

const Header = ({ logo, scrollToFeatureBox }) => {
	// State to track if the page is scrolled
	const [isScrolled, setIsScrolled] = useState(false);
	const navigate = useNavigate(); // Use the navigate function from useNavigate hook
	const handleClick = (e) => {
		// Check if already on the homepage
		if (window.location.pathname === "/") {
			e.preventDefault(); // Prevent default link behavior
			scrollToTop(); // Scroll to top
		} else {
			// Navigate to homepage, which will automatically be at the top
			navigate("/");
		}
	};

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: "smooth", // Optional: Add smooth scrolling
		});
	};

	// Effect to add scroll event listener
	useEffect(() => {
		const handleScroll = () => {
			// Set isScrolled to true if page is scrolled beyond 50 pixels
			if (window.scrollY > 50) {
				setIsScrolled(true);
			} else {
				setIsScrolled(false);
			}
		};

		// Add scroll event listener
		window.addEventListener("scroll", handleScroll);

		// Clean up event listener
		return () => window.removeEventListener("scroll", handleScroll);
	}, []); // Empty dependency array means this runs once on mount

	return (
		<Flex
			as="header"
			position="fixed"
			w="full"
			// Change background color based on isScrolled state
			bg={isScrolled ? "white" : "#B9F5E7"}
			zIndex="1000"
			h={{ base: "80px", md: "100px" }}
			px={{ base: "5%", md: "8%", lg: "10%" }} // Adjusted padding for responsiveness
			align="center" // Align items vertically
			justify="space-between" // Spaces out the logo and the sign-up button
		>
			{/* Logo */}
			<Box
				w={{ base: "40%", md: "15%" }}
				// ml={{ base: "10%", md: "15%", lg: "20%" }}
			>
				<Flex align="center" justify="left">
					<Link to="/" onClick={handleClick}>
						<Image
							src={logo}
							alt="Company Logo"
							boxSize={{ base: "100px", md: "120px" }}
							objectFit="contain"
						/>
					</Link>
				</Flex>
			</Box>

			{/* Sign-Up Button */}
			<Flex
				w={{ base: "45%", md: "20%" }}
				textAlign="center"
				// mr={{ base: "4px", md: "40px" }}
				alignContent={{ base: "right", md: "flex-end" }}
				minW={{ base: "150px", md: "200px" }}
			>
				<Button
					bg="#F8F142"
					variant="solid"
					textColor="#265F5C"
					borderRadius="30px"
					size={{ base: "md", md: "lg" }} // Set the size to large
					boxShadow="0px 4px 6px rgba(38, 95, 92, 0.5)" // drop shadow with the specified color
					onClick={scrollToFeatureBox}
					_hover={{ bg: "#265F5C", textColor: "#F8F142" }}
				>
					Join the Waitlist
				</Button>
			</Flex>
		</Flex>
	);
};

export default Header;
