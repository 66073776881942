import React from "react";
import { Box, Flex, Heading, Text, Image, VStack } from "@chakra-ui/react";
import WaitlistSignup from "./WaitlistSignup";
import appDemo from "../assets/Sumly-App-Demo.png";
import logo_dark from "../assets/Sumly-logo-dark.png";

const HeroSection = () => {
	return (
		<VStack
			spacing={4} // Adjust spacing between items
			pt={{ base: "60px", sm: "80px", md: "100px" }} // Add padding-top to push content below the fixed header
			pb={{ base: "20px", sm: "80px", md: "0px", lg: "100px" }}
			align="stretch" // Align children to stretch to the full width
			w="full"
			minH={{ base: "auto", lg: "80vh" }}
			bg="#B9F5E7"
		>
			<Flex
				justify="space-between" // Ensure the child elements are spaced out
				align="center" // Align items vertically
				direction={{ base: "column", md: "row" }} // Stack on small screens, horizontal on medium and up
				mx="auto" // Center the Flex container
				maxW={{ base: "full", md: "80vw" }} // Max width to ensure content doesn't stretch too far on large screens
				w="full" // Use the full width available
				minH={{ base: "auto", lg: "70vh" }}
			>
				{/* Text and Waitlist Signup occupying 2/3 of the space */}
				<Box
					flex={{ base: 1, md: 3 }}
					ml={{ base: "0", md: "0" }}
					// mr={{ base: "0", md: "8" }}
					order={{ base: 2, md: 1 }}
					mb={{ base: "8", md: "0" }}
					py={{ base: "4", sm: "10", md: "20" }} // Responsive padding ensures the content's spacing adapts to the screen size.
				>
					<Heading
						as="h2"
						mt="0"
						mb="0"
						fontSize={{ base: "36px", sm: "40px", md: "50px", lg: "55px" }} // Font size adjusts for mobile (base) and medium-sized (md) screens.
						textAlign={{ base: "center", md: "left" }} // Text alignment changes from center on small screens to left on wider screens.
						color="#559390"
						fontWeight="500"
					>
						Lower taxes.
					</Heading>
					<Heading
						as="h1"
						mt="0"
						mb={{ base: "6", md: "10" }}
						fontSize={{ base: "60px", md: "120px" }} // Large heading that significantly increases in size on medium-sized screens.
						textAlign={{ base: "center", md: "left" }}
						color="#265F5C"
						fontFamily="F37Boost" // Custom font family for stylistic preference.
						lineHeight="1" // Adjust lineHeight to control the spacing between lines of text.
					>
						<Box
							as="span"
							display="inline" // Ensure inline display for natural flow in text
							mr="0" // Adjust right margin to control space before highlight begins
						>
							BIGGER
						</Box>
						<Text
							as="span"
							bg="#F8F142"
							pl={{ base: "3", md: "8" }} // Apply horizontal padding to include space in the highlight
							py="0"
							borderRadius="60px" // Rounded background for emphasis.
							display="inline-flex"
							alignItems="center"
							justifyContent="center"
						>
							SAVINGS.
						</Text>
					</Heading>

					<Heading
						as="h2"
						fontSize={{ base: "14px", md: "24px" }}
						mb={{ base: "3", md: "6" }}
						textAlign={{ base: "center", md: "left" }}
						fontWeight="500"
						color="#265F5C"
						width="100%"
					>
						Sumly is the expense tracking platform
						<br /> built exclusively for the self employed.
					</Heading>
					<Heading
						as="h2"
						fontSize={{ base: "sm", md: "lg" }}
						mt={{ base: "5", md: "10" }}
						textAlign={{ base: "center", md: "left" }}
						color="#265F5C"
						fontWeight="bold"
					>
						Join the waitlist for first access to the app.
					</Heading>
					<Flex align={{ base: "center", md: "left" }}>
						<WaitlistSignup
							width={{ base: "100%", sm: "90%", md: "80%", lg: "75%" }}
						/>
					</Flex>
					{/* <Flex align="center" justify={{ base: "center", md: "left" }} mt="4">
						<Image src={logo_dark} alt="Sumly-logo-dark" height="40px" />
						<Text
							mx="2"
							fontSize={{ base: "16px", md: "20px" }}
							lineHeight="20px"
							fontStyle={"italic"}
							color="#265F5C"
						>
							Averaging Savings $1,300
						</Text>
						<Image src={logo_dark} alt="Sumly-logo-dark" height="40px" />
					</Flex> */}
				</Box>

				{/* Image occupying 1/3 of the space */}
				<Box
					flex={{ base: 1, md: 3 }}
					px={{ base: "0", sm: "0", md: "0" }}
					order={{ base: 1, md: 2 }}
				>
					<Image
						src={appDemo}
						alt="App Screenshot"
						boxSize={{ base: "100%", sm: "90%", md: "120%" }}
						maxWidth={{ base: "350px", sm: "400px", md: "none" }}
						objectFit="cover"
						mx="auto"
					/>
				</Box>
			</Flex>
		</VStack>
	);
};

export default HeroSection;
