import React, { useState } from "react";
import {
	Flex,
	Input,
	Button,
	useToast,
	Box,
	Heading,
	VStack,
} from "@chakra-ui/react";

const WaitlistSignup = ({
	buttonColor = "#037676",
	width = "full",
	textColor = "#FFF",
	highlight,
}) => {
	const [email, setEmail] = useState("");
	const toast = useToast();

	const handleSubmit = async (e) => {
		e.preventDefault();

		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/create`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						"x-api-key": process.env.REACT_APP_API_KEY,
					},
					body: JSON.stringify({ email }),
				}
			);

			const data = await response.json(); // Assuming JSON response

			if (response.ok) {
				toast({
					title: "Success!",
					description: data.message, // Use the message from your API response
					status: "success",
					duration: 5000,
					isClosable: true,
				});
				setEmail("");
			} else {
				toast({
					title: "Error",
					description:
						data.message ||
						"There was a problem with your signup. Please try again.",
					status: "error",
					duration: 5000,
					isClosable: true,
				});
			}
		} catch (error) {
			console.error(error);
			let errorMessage = "Please check your connection and try again.";
			if (error instanceof SyntaxError) {
				errorMessage = "Unexpected response format.";
			}
			toast({
				title: "Network Error",
				description: errorMessage,
				status: "error",
				duration: 5000,
				isClosable: true,
			});
		}
	};

	return (
		<Flex
			as="form"
			onSubmit={handleSubmit}
			direction={{ base: "column", md: "row" }}
			align="end"
			justify="end"
			w={width}
			mt={{ base: "2", md: "4" }}
			boxShadow={highlight ? "0px 0px 20px 5px #F8F142" : "none"}
			borderRadius={{ base: "10px", md: "30px" }}
		>
			<Input
				type="email"
				value={email}
				onChange={(e) => setEmail(e.target.value)}
				placeholder="Enter your email"
				size={{ base: "md", md: "lg" }}
				flex={{ base: "full", md: "6", lg: "4" }}
				mr={{ base: 0, md: -10, lg: -20 }} // Adjust right margin for responsiveness, use negative value on md to create overlap
				h={{ base: "50px", md: "60px" }}
				borderRadius={{ base: "10px", md: "30px" }}
				bg={"white"}
				zIndex={1} // Ensure input is above the button for md+
				boxShadow="2px 4px 10px rgba(38, 95, 92, 0.3), 4px 6px 15px rgba(38, 95, 92, 0.15)"
				// Adjusted for right-bottom shadow with specific color
			/>
			<Button
				type="submit"
				flex={{ base: "full", md: "1" }}
				w={{ base: "full", md: "auto" }}
				backgroundColor={buttonColor} // Use buttonColor prop for background color
				_hover={{
					bg: "#265F5C", // Adjust for a matching or contrasting hover color
				}}
				color={textColor}
				h={{ base: "50px", md: "60px" }}
				size={{ base: "md", md: "lg" }}
				borderRadius={{ base: "10px", md: "30px" }}
				ml={{ base: 0, md: -10 }} // Use negative left margin on md to create overlap
				mt={{ base: 2, md: 0 }} // Add top margin on mobile if the button wraps
				zIndex={2} // Ensure button is above the input
				boxShadow="2px 4px 10px rgba(38, 95, 92, 1)"
				// Adjusted for right-bottom shadow with specific color
			>
				I'm in!
			</Button>
		</Flex>
	);
};
export default WaitlistSignup;
