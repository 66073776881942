import React, { useRef, useState } from "react";
import Header from "./Header";
import logo from "../logo.svg";
import {
	Text,
	Box,
	Flex,
	Heading,
	Image,
	VStack,
	List,
	ListItem,
	useColorModeValue,
	useBoolean,
    Button
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom"; // Import Link from react-router-dom

const Support = () => {
    const navigate = useNavigate();
    const handleClick = (e) => {
        navigate("/");
    };
    
	return (
        <VStack>
            <Flex
                position="relative"
                w="full"
                // Change background color based on isScrolled state
                zIndex="1000"
                h={{ base: "80px", md: "120px" }}
                px={{ base: "5%", md: "8%", lg: "10%" }} // Adjusted padding for responsiveness
                align="center" // Align items vertically
                justify="space-between" // Spaces out the logo and the sign-up button
            >
                <Box
                    w={{ base: "40%", md: "15%" }}
                    // ml={{ base: "10%", md: "15%", lg: "20%" }}
                >
                    <Flex align="center" justify="left">
                        <Link to="/" onClick={handleClick}>
                            <Image
                                src={logo}
                                alt="Company Logo"
                                boxSize={{ base: "100px", md: "150px" }}
                                objectFit="contain"
                            />
                        </Link>
                    </Flex>
                </Box>

                <Flex
                    w={{ base: "45%", md: "20%" }}
                    textAlign="center"
                    // mr={{ base: "4px", md: "40px" }}
                    alignContent={{ base: "right", md: "flex-end" }}
                    minW={{ base: "150px", md: "200px" }}
                >
                    <Button
                        bg="#265F5C"
                        variant="solid"
                        textColor="#FFFFFF"
                        borderRadius="30px"
                        size={{ base: "md", md: "lg" }} // Set the size to large
                        boxShadow="0px 4px 6px rgba(38, 95, 92, 0.5)" // drop shadow with the specified color
                        onClick={handleClick}
                        _hover={{ bg: "#2e706d", textColor: "#FFFFFF" }}
                    >
                        Join the Waitlist
                    </Button>
                </Flex>
            </Flex>
            <Flex
                position="relative"
                pt="5%"
            >
                <Heading
                    size={{ base: "lg", md: "xl", lg: "2xl" }} // Adjust size as needed
                    color="#265F5C" // Adjust color to fit the design
                    textAlign="center" // Center the text
                    mb={{ base: "2", md: "3", lg: "4" }}
                    mt={{ base: "4", md: "6", lg: "8" }}
                    fontWeight="500"
                >
                    Need Support?
                </Heading>
            </Flex>
            <Flex
                position="relative"
            >
                <Heading
                    fontSize="1.5em" // Adjust size as needed
                    color="#265F5C" // Adjust color to fit the design
                    textAlign="center" // Center the text
                    mt={{ base: "4", md: "6", lg: "8" }}
                    fontWeight="500"
                >
                    Contact Sumly Support:
                </Heading>
            </Flex>
            <Flex
                position="relative"
            >
                <Link
                    to="mailto:support@getsumly.com"
                >
                    <Heading
                        fontSize="1.5em" // Adjust size as needed
                        color="#265F5C" // Adjust color to fit the design
                        textAlign="center" // Center the text
                        fontWeight="500"
                    >
                        support@getsumly.com
                    </Heading>
                </Link>
            </Flex>
        </VStack>
	);
};

export default Support;
