import React, { useRef, useState } from "react";
import { Flex, VStack, Box } from "@chakra-ui/react";
import HeroSection from "./HeroSection";
import MissionSection from "./MissionSection";
import FeaturesSection from "./FeaturesSection";
import Footer from "./FooterSection";
import Header from "./Header";
import logo from "../logo.svg"; // Update the path to where your logo file is
// import './LandingPage.css'; // Assuming you have a CSS file for overall styles
const ContentContainer = ({ children }) => {
	return (
		<Box justify="center" align={"center"}>
			{children}
		</Box>
	);
};

const LandingPage = () => {
	// Create ref
	const lastFeatureBoxRef = useRef(null);
	// Update the onClick handler to use scrollToFeatureBox prop
	const [highlightSignup, setHighlightSignup] = useState(false);

	const scrollToFeatureBox = () => {
		lastFeatureBoxRef.current?.scrollIntoView({ behavior: "smooth" });
		setHighlightSignup((prevState) => !prevState); // Toggle to trigger effect
		setTimeout(() => setHighlightSignup((prevState) => !prevState), 1000); // Optionally turn off after some time
	};

	return (
		<ContentContainer>
			<Header logo={logo} scrollToFeatureBox={scrollToFeatureBox} />
			<HeroSection w={{ base: "100%", md: "100%" }} />
			<MissionSection w={{ base: "100%", md: "100%" }} />
			<FeaturesSection
				ref={lastFeatureBoxRef}
				w={{ base: "100%", md: "100%" }}
				highlightSignup={highlightSignup}
			/>
			<Footer w={{ base: "100%", md: "100%" }} />
		</ContentContainer>
	);
};

export default LandingPage;
