import React, { useState, useEffect } from "react";

const PrivacyPolicy = () => {
    
  const [termsContent, setTermsContent] = useState("");

  useEffect(() => {
    fetch("/privacy_policy.txt")
      .then((response) => response.text())
      .then((data) => setTermsContent(data))
      .catch((error) => console.error("Error fetching terms:", error));
  }, []);

  return (
    <div style={{ whiteSpace: "pre-wrap", padding: "20px" }}>
      <h1>Sumly Privacy Policy</h1>
      <p>{termsContent}</p>
    </div>
  );
};

export default PrivacyPolicy;