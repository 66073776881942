import React from "react";
import {
	Heading,
	Box,
	Flex,
	Image,
	Text,
	useBreakpointValue,
} from "@chakra-ui/react";

// Update the path to your image
import missionImage from "../assets/mission-image.png";

const MissionSection = () => {
	// Responsive settings for padding based on the screen size
	const paddingX = useBreakpointValue({ base: "4", md: "8" });

	return (
		<Box
			w="full" // Ensure the background is full width
			h={{ base: "auto", lg: "80vh" }} // Full viewport height
			minH={{ base: "auto", lg: "550px" }} // Minimum viewport height
			// bg="#F8F142" // Background color
			display="flex"
			flexDirection="column"
			alignItems="center" // Vertically center the content box
			justifyContent="center" // Horizontally center the content box
			px={paddingX} // Responsive horizontal padding to ensure some space on smaller screens
			py={{ base: "4", md: "8" }}
		>
			<Flex
				direction="column" // Stack children vertically for all screen sizes
				w={{ base: "90%", md: "85%", lg: "80%" }} // Box width as a percentage of its parent container
				h={{ base: "auto", lg: "85%" }} // Adjust height accordingly
				bg="#F8F142" // Background color for the box
				borderRadius="lg" // Rounded corners
				alignItems="center" // Center children horizontally
				justify="center" // Center children vertically
				textAlign="center" // Center text for all children
				minH={{ base: "auto", md: "550px", lg: "400px" }} // Minimum height for the box
			>
				{/* Heading with image overlay directly above */}
				<Box
					position="relative" // Required to position the image absolutely within this box
					px={{ base: "4", sm: "8", md: "6", lg: "20", xl: "3%" }}
					py={{ base: "8", sm: "8", md: "6", lg: "12", xl: "3%" }}
					w="100%" // Ensure the box takes full width of its container
				>
					<Box
						position="absolute" // Position the image absolutely to overlay on top of the text
						top={{ base: "50px", sm: "20%", md: "-7%", lg: "15%", xl: "0%" }} // Align the top edge of the image box with the top edge of its parent box
						left={{ base: "20%", md: "16%", xl: "20%" }} // Center the image horizontally
						transform={{
							base: "translateX(5%)",
							sm: "translateX(8%)",
							md: "translateX(9%)",
							lg: "translateX(10%)",
							xl: "translateX(9%) translateY(-10%)",
						}} // Adjust positioning to truly center the image
						w={{ base: "30%", sm: "20%", md: "16%", lg: "14%", xl: "11%" }}
						mt={{ base: "-5%", md: "8%", lg: "-2%", xl: "0%" }} // Negative margin to pull the image up above the text
					>
						<Image
							src={missionImage}
							alt="Mission"
							objectFit="contain"
							w="100%" // Make image width 100% of its container
							h="auto"
						/>
					</Box>
					<Flex
						pt={{ base: "30%", md: "6%", lg: "5%", xl: "2%" }} // Padding to move the Flex container down, creating space from the top edge
						align={{ base: "center", md: "center" }} // Align items vertically in the center, if needed
						alignItems={{ base: "flex-center", md: "flex-end" }} // Align items to the center on small screens, to the start on larger screens
						justify="center" // Center the items horizontally, if you want a centered layout
						direction={{ base: "column", sm: "column", md: "row" }} // Stack them vertically on small screens, side-by-side on larger screens
					>
						<Heading
							as="h2"
							mb="0"
							fontSize={{ base: "40px", md: "60px", lg: "80px" }}
							pl={{ base: "2", md: "0" }}
							color="#265F5C"
							fontFamily="F37Boost"
							pr={{ base: "0", md: "2", lg: "4" }} // Right padding for spacing between the headings
						>
							MINIMIZE TAXES,
						</Heading>
						<Heading
							as="h1"
							fontSize={{ base: "55px", md: "80px", lg: "120px" }}
							color="#265F5C"
							fontFamily="F37Boost"
							mb="0"
						>
							<Text
								as="span"
								bg="#C5F3E8"
								pl={{ base: "1", lg: "2" }}
								borderRadius="10px"
								display="inline-flex"
								alignItems="center"
								justifyContent="center"
							>
								MAXIMIZE INCOME.
							</Text>
						</Heading>
					</Flex>
				</Box>
				<Text
					mt="0"
					fontSize={{ base: "xl", md: "2xl" }}
					color="#265F5C"
					w={{ base: "90%", md: "75%" }}
					mb={{ base: "8", md: "0" }}
				>
					Our mission is simple: we’re your business copilot. <br /> Building a
					business is hard. We make the no-fun stuff (expenses, taxes, etc.)
					easy and save you money.
				</Text>
			</Flex>

			{/* <Box
				w={{ base: "90%", sm: "85%", md: "80%" }} // Ensure this box has the same width as the Flex box above
				textAlign="center"
				bg="#C5F3E8"
				px={{ base: "4px", md: "12px" }}
				py={{ base: "2px", md: "8px" }}
			>
				<Text
					fontSize={{ base: "26px", md: "40px" }}
					color="#265F5C"
					fontFamily={"F37Boost"}
				>
					A good way to build your business? Don’t overpay on taxes!
				</Text>
			</Box> */}
		</Box>
	);
};

export default MissionSection;
